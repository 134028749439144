import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Icons
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const darkModeSwitch = (props) => {
  const changeTheme = () => {
    props.handleThemeChange();
  };

  const darkModeIcon = !props.isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />;

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch data-testid="darkModeSwitch" checked={props.isDarkTheme} onChange={changeTheme} />}
        label={darkModeIcon}
      />
    </FormGroup>
  );
};

export default darkModeSwitch;
